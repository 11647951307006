<template>
  <div class="home" id="home" name="home">
    <MyMenu :menuname="'index'"></MyMenu>
<!--    <swiper></swiper>-->
    <!-- 轮播图 -->
    <div class="banner-class"> <div class="block">
      <!-- 分类标签 -->
      <div class="nav" @mouseleave="navShow = false">
        <div v-show="navShow"   class="product-right">
          <div class="product-nav" v-for="(item,index) in categoryList" :key="item.twoClassCode">
            <div class="title">{{ item.secondaryClassifyName }}</div>
            <div class="item">
<!--              <div class="clss">全部</div>-->
              <div class="clss" v-for="attr in item.tertiaryPropertyList" :key="attr.propertyId" @click="goGoodsDetail(attr.propertyId,index)">{{ attr.tertiaryClassifyName }}</div>
              <!--              <el-radio-group v-model="reqParams['params' + (index + 1)]">-->
              <!--                <el-radio border  size="medium" label="99999" @click.native.prevent="handleClickCategory('99999', index + 1)">全部</el-radio>-->
              <!--                <el-radio border size="medium" v-for="attr in item.tertiaryPropertyList" :key="attr.propertyId" :label="attr.propertyId" @click.native="handleClickCategory(attr.propertyId, index + 1)">{{ attr.tertiaryClassifyName }}</el-radio>-->
              <!--              </el-radio-group>-->
            </div>
          </div>
          <!--          <div class="product-nav">-->
          <!--            <div class="title">使用场景</div>-->
          <!--            <div class="item">-->
          <!--              <el-radio-group v-model="recommendLabel">-->
          <!--                <el-radio label="99999" border size="medium" @click.native.prevent="clickSence('99999')">全部</el-radio>-->
          <!--                <el-radio v-for="item in homeCategoryList" size="medium" border :key="item.labelName" :label="item.labelName" @click.native.prevent="clickSence(item.labelName)">{{ item.labelName }}</el-radio>-->
          <!--              </el-radio-group>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <div v-for="(item, index) in leftNavList" :key="index" :class="primaryClassifyCode == item.primaryClassifyCode ? 'left-item active' : 'left-item'" @click="goCategory(item.primaryClassifyCode)"  @mouseover="handleClickNav(item.primaryClassifyCode, index)">
          <span>{{ item.primaryClassifyName }}</span>
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="banner">
        <el-carousel>
          <el-carousel-item v-for="item in carousel" :key="item.carousel_id">
            <a :href="item.targetUrl">
              <img style="width: 100%; height: 100%;" :src="item.imgUrl" :alt="item.title" />
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div></div>
    <!-- 轮播图END -->
    <div class="main-box">
      <div class="main">
        <!-- 手机商品展示区域 -->
        <div class="phone" v-for="(item, index) in CommodityList" :key="index">
          <div class="box-hd">
            <div class="titleBox">
              <div class="left">
                <div class="big">{{ item.labelName }}</div>
                <div class="min"></div>
              </div>
              <div class="right" v-if="item.apiCommodityListPageVos.length>10">
                <router-link :to="{ path: '/goods', query: { recommendLabel: item.labelName } }">
                  浏览更多
                  <i class="el-icon-d-arrow-right"></i>
                </router-link>
              </div>
            </div>
<!--            <div class="title">{{ item.labelName }}</div>-->
          </div>
          <div class="box-bd">
            <div class="promo-list">
              <img v-if="item.apiCommodityListPageVos.length >= 5" :src="item.highBannerUrl" />
              <img v-else :src="item.lowBannerUrl" />
            </div>
            <div class="list">
              <MyList :list="item.apiCommodityListPageVos" :pagename="'home'" :recommendLabel="item.labelName" :isMore="true"></MyList>
            </div>
          </div>
        </div>
        <!-- 手机商品展示区域END -->
      </div>
    </div>
  </div>
</template>
<script>
import {commodityPropertyList,indexVo} from '@/api/category/index'
export default {
  data() {
    return {
      navShow:false,
      reqParams:{},
      primaryClassifyCode:'',// 左侧一级分类列表
      categoryList: [], //分类列表
      leftNavList: [],// 分类数据
      homeCategoryList: [], //首页全部分类
      carousel: '', // 轮播图数据
      phoneList: '', // 手机商品列表
      miTvList: '', // 小米电视商品列表
      applianceList: '', // 家电商品列表
      applianceHotList: '', //热门家电商品列表
      accessoryList: '', //配件商品列表
      accessoryHotList: '', //热门配件商品列表
      protectingShellList: '', // 保护套商品列表
      chargerList: '', //充电器商品列表
      applianceActive: 1, // 家电当前选中的商品分类
      accessoryActive: 1, // 配件当前选中的商品分类
      CommodityList: [] //商品列表
    }
  },
  watch: {},
  created() {
    this.getHomeData()
    this.getCommodityPropertyList()
  },
  methods: {
    getHomeData(){
      // 获取轮播图数据
      indexVo().then((res) => {
            this.carousel = res.data.data.apiBannerListVO.topBanner
            this.CommodityList = res.data.data.apiCommodityLabelListVo.commodityLabelList
          })
          .catch((err) => {
            return Promise.reject(err)
          })
    },
    getCommodityPropertyList(){
      commodityPropertyList().then(({data})=>{
        if(data.code == 200){
          this.leftNavList = data.data.primaryPropertyList
          this.primaryClassifyCode = this.leftNavList[0].primaryClassifyCode
          this.categoryList = this.leftNavList[0].secondaryPropertyList
        }else {
          this.$message.error(data.msg)
        }
      })
    },
    handleClickNav(primaryClassifyCode, index){
      this.navShow = true
      this.primaryClassifyCode = primaryClassifyCode
      this.categoryList = this.leftNavList[index].secondaryPropertyList
    },
    goCategory(title){
      this.$router.push({
        path:'/goods',
        query: { name: title }
      })
    },
    goGoodsDetail(id,index){
      this.$router.push({
        path:'/goods',
        query: {name:this.primaryClassifyCode, id,index }
      })
    },
    handleClickCategory(){

    },
    // 获取家电模块子组件传过来的数据
    getChildMsg(val) {
      this.applianceActive = val
    },
    // 获取配件模块子组件传过来的数据
    getChildMsg2(val) {
      this.accessoryActive = val
    }
  }
}
</script>
<style scoped lang="scss">
@import '../../assets/css/index.css';
.banner-class{
  background: #f5f5f5;
  height: 470px;
}
.block{
  position: relative;
  top: 20px;
  display: flex;
  background: #fff;
  height: 450px;
    .product-right{
        width: 40%;
        height: 430px;
        position: absolute;
          left: 234px;
        background: rgba(255, 255, 255, 0.4);
        backdrop-filter: blur(10px); /* 毛玻璃模糊效果 */
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1); /* 阴影 */
        padding-top: 20px;
        z-index: 99;
        .product-nav{
          display: flex;
          //align-items: center;
          flex-wrap: wrap;
          margin-bottom: 30px;
        }
        .title{
          width: 100px;
          line-height: 36px;
          text-align: center;
          font-weight: bold;
          display: inline-block;
          vertical-align: top;
        }
        .item{
          width: calc(96% - 100px);
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          box-sizing: border-box;
          padding-bottom: 10px;
          border-bottom: 1px dashed #eee;
          .clss{
            margin: 0 10px;
            cursor: pointer;
          }
        }
        .el-radio{
          margin-bottom: 12px;
          margin-right: 0px;
          ::v-deep .el-radio__input {  display: none !important;}
        }
        .el-radio--medium.is-bordered {
          margin-right: 10px;
        }
    }
    .banner{
      background: #6a737d;
    }
    .nav{

      background: #fff;
      background: #fff;
      width: 234px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .left-item{
        font-size: 16px;
        line-height: 35px;
        //border-radius: 6px;
        padding: 4px 14px;
        cursor: pointer;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        .active{
          background: #409eff;
          color: #fff;
        }
        i{
          font-size: 18px;
        }
      }
      .left-item:hover{
        background: #409eff;
        color: #fff;
      }
    }
    .banner{
      width: calc(100% - 234px);
    }
  }
.titleBox{
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 60px;
  .big{
    font-size: 30px;
    font-weight: bold;
  }
  .right{
    a{
      color: #409eff;
    }
  }
}
</style>
