import Axios from 'axios'

// 向后端请求分类列表数据
export const indexVo = (params) => Axios({
    url: '/zuwu-api/official/index?applicationType=3',
    method: 'get',
    params
});

// 大分类数据
export const commodityPropertyList = (params) => Axios({
    url: '/zuwu-api/official/commodityPropertyList',
    method: 'get',
    params
});

// // 大分类数据
// export const commodityClassPropertyList = (data) => Axios({
//     url: '/zuwu-api/official/commodityClassPropertyList',
//     method: 'post',
//     data
// });

// 通过搜索条件向后端请求商品数据
export const getPageList = (params) => Axios({
    url: '/zuwu-api/official/page',
    method: 'get',
    params
});


// 新接口


// 商品分类
export const getPropertyClassify = (params) => Axios({
    url: '/zuwu-api/web/commodity/propertyClassify',
    method: 'get',
    params
});

// 根据商品属性获取商品列表
export const commodityClassPropertyList = (data) => Axios({
    url: '/zuwu-api/web/commodity/commodityClassPropertyList',
    method: 'post',
    dataType: 'json',
    data
});

// 商品详情
export const officialCommodityDetail = (params) => Axios({
    url: '/zuwu-api/web/commodity/officialCommodityDetail',
    method: 'get',
    params
});
// sku列表
export const skuList = (params) => Axios({
    url: '/zuwu-api/web/commodity/skuList',
    method: 'get',
    params
});

// 租赁模式列表
export const commoditySkuList = (params) => Axios({
    url: '/zuwu-api/web/commodity/commoditySkuList',
    method: 'get',
    params
});
// 根据skuid和模式id查询价格
export const detailByLeasePatternId = (params) => Axios({
    url: '/zuwu-api/web/commodity/detailByLeasePatternId',
    method: 'get',
    params
});
// 收藏商品
export const collectCommodity = (params) => Axios({
    url: '/zuwu-api/web/commodity/collectCommodity',
    method: 'get',
    params
});
// 是否收藏商品
export const isCollect = (params) => Axios({
    url: '/zuwu-api/web/commodity/isCollect',
    method: 'get',
    params
});
// 收藏商品列表
export const checkAllCollect = (params) => Axios({
    url: '/zuwu-api/web/commodity/checkAllCollect',
    method: 'get',
    params
});
//  生成海报
export const createPoster = (params) => Axios({
    url: '/zuwu-api/web/commodity/createPoster',
    method: 'get',
    params
});